@import "/src/assets/font/stylesheet.css";
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;600&family=Space+Grotesk:wght@400;700&display=swap");
@import "/src/assets/font/WEB/css/clash-display.css";

* {
  margin: 0;
  padding: 0;
  list-style-type: none;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  border: none;
}

:root {
  --bg-color: #1f1f25;
  --text-color: #ffffff;
  --text-hover: rgba(255, 255, 255, 0.24);
  --link-hover: rgba(255, 255, 255, 0.48);
  --bg-text-gradient: #d3b6b8;
  --container-width-lg: 1440px;
  --container-width-ms: 390px;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
  width: 0;
}

body {
  background: var(--bg-color);
  color: var(--text-color);
  font-family: "Space Grotesk", sans-serif;
}

section {
  background-image: url(./assets/images/texture.png);
  background-repeat: repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
}
.nav-items * {
  display: flex;
  align-items: center;
}

.header {
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  color: #d3b6b8;
  letter-spacing: 0.04em;
  width: fit-content;
  margin-top: 48px;
  max-width: 550px;
  text-transform: capitalize;
}

.website-container {
  position: relative;
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 7;
  display: flex;
  top: 0;
}

.overlay .logo-name-wrapper {
  position: absolute;
  top: 40px;
  left: 40px;
}

.overlay .logo-name-wrapper .logo-name {
  font-weight: 700;
  margin-bottom: 10px;
  position: relative;
}

.overlay .logo-name-wrapper .logo-name .inner-logo-name {
  display: flex;
  align-items: flex-end;
  margin-top: 12px;
}

.overlay .logo-name-wrapper .logo-name h2 {
  display: flex;
  font-size: 16px;
  background: linear-gradient(90deg, #c2bdeb 2.07%, #e1af8d 99.94%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.line-container {
  display: flex;
  align-items: center;
}

.overlay .logo-name-wrapper .loader-line {
  width: 145px;
  height: 3px;
  border-radius: 100px;
  background: var(
    --gradient,
    linear-gradient(90deg, #e1af8d 2.07%, #c2bdeb 99.94%)
  );
  transform: translateY(4px);
  margin: 0 1px;
}

.loader-line-dots {
  background: linear-gradient(90deg, #c2bdeb 2.07%, #e1af8d 99.94%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

.loader-copyright {
  display: flex;
  justify-content: flex-end;
  background: linear-gradient(90deg, #c2bdeb 2.07%, #e1af8d 99.94%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  width: fit-content;
  position: absolute;
  right: 0;
  font-size: 16px;
}

.bar {
  width: 10vw;
  height: 105vh;
  background: var(--bg-color);
}

.counter {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 8;
  color: #bcbcc4;
  padding: 10px 40px;
  font-size: 20vw;
}

@keyframes draw {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes fill-it {
  100% {
    fill: linear-gradient(90deg, #c2bdeb 2.07%, #e1af8d 99.94%);
  }
}

/* =============== GENERAL STYLES =============== */
section {
  margin: 8px 0;
}

.container {
  max-width: var(--container-width-lg);
  padding: 0 80px;
  margin: 0 auto;
  min-height: 100vh;
}

.pin-img {
  animation-name: moving-emoji;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
}

/* =========== MEDIA QUERIES (SMALL DEVICES) =========== */
@media (max-width: 1204px) {
  section {
    margin: 4px 0;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  .container {
    max-width: var(--container-width-ms);
    margin: 0 auto;
    height: auto;
    padding: 0 24px;
  }

  .header {
    margin-top: 40px;
    font-size: 32px;
  }

  .overlay .logo-name-wrapper {
    left: 24px;
  }

  .counter {
    padding: 0 24px;
  }
}

@keyframes moving-emoji {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.about-section {
  overflow: hidden;
}
.about-section .about-wrapper {
  position: relative;
  height: unset !important;
}
.about-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
}

.about-wrapper .about-hero-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about-hero-flex .about-hero-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  max-width: 440px;
  margin-top: 40px;
}

.about-hero-flex .about-hero-text .about-hero-subtext {
  margin-top: 20px;
}

.about-wrapper-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 100px;
}

.about-container_items .about-header {
  max-width: 725px;
  font-size: 48px;
  line-height: 61px;
  text-transform: unset;
  position: relative;
  margin-top: 0 !important;
}

.about-header .header-cross {
  margin-right: 0;
}

.about-header .header-cross:first-child::before {
  content: " ";
  position: absolute;
  top: 100%;
  width: 150px;
  left: 0;
  height: 2px;
  border-radius: 2px;
  background: var(--bg-text-gradient);
  transform: translateY(-30px);
}

.about-header .header-cross:last-child::before {
  content: " ";
  position: absolute;
  top: 100%;
  width: 150px;
  left: 175px;
  height: 2px;
  border-radius: 2px;
  background: var(--bg-text-gradient);
  transform: translateY(-30px);
  margin-right: 0;
}

.about-container_items .header-cross_line:first-child {
  margin-right: 0;
}

.about-blur-container {
  margin-left: 0 !important;
  width: 600px;
  position: absolute;
  top: 0;
}

.about-container .about-container_items .item-right {
  font-weight: 700;
  font-size: 21px;
  line-height: 26px;
  text-align: right;
  letter-spacing: 0.02em;
  max-width: 316px;
  font-family: "Mulish";
  transform: translateY(80px);
}

.about-container_items .item-right .about-line {
  margin-right: 0;
  display: block;
  width: 38px;
  height: 2px;
  background: var(--bg-text-gradient);
  position: absolute;
  bottom: 10px;
  right: 135px;
  letter-spacing: 0.02em;
}

.about-footer {
  display: inline-block;
  width: 100%;
}

/* =========== MEDIA QUERIES (SMALL DEVICES) =========== */
@media (max-width: 1204px) {
  .about-container {
    flex-direction: column;
  }

  .about-hero-flex .about-hero-text {
    padding-bottom: 80px;
  }

  .about-wrapper-flex {
    min-height: unset;
    padding-top: 0;
    padding-bottom: 0;
  }

  .about-container_items .about-header {
    padding-top: 200px;
    font-size: 40px;
  }
  .about-header .header-cross:first-child::before {
    width: 125px;
    transform: translateY(-89px);
  }

  .about-header .header-cross:last-child::before {
    width: 125px;
    transform: translate(-29px, -89px);
  }

  .about-container .about-container_items .item-right {
    transform: translate(0);
    margin-top: 37px;
  }

  .about-wrapper .about-hero-flex {
    flex-direction: column;
  }
}

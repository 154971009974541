.stack-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.stack-container .stack-logo-container {
  margin-top: 110px;
  display: flex;
  align-items: center;
}

.stack-container .stack-header {
  text-transform: none !important;
}

.stack-container .stack-logo-container .my-logo {
  width: 362.33px;
  margin-left: -50px;
  /* transform: translateY(36px); */
}

.stack-container .stack-logo-container .my-mobile-logo {
  display: none;
}

.stack-logo-container .stark-flex {
  display: flex;
  gap: 32px;
  justify-content: flex-end;
  /* margin-top: -290px; */
  position: relative;
}

.stack-logo-container .stark-flex .start-flex_item:nth-child(2) {
  display: flex;
  align-items: center;
}

.stack-logo-container .stark-flex .start-flex_item:nth-child(4) {
  margin-top: -91px;
}
.stack-logo-container .stark-flex .start-flex_item:nth-child(5) {
  margin-top: -182px;
}

.logo-second_item {
  margin-top: 32px;
}

/* =========== MEDIA QUERIES (SMALL DEVICES) =========== */
@media (max-width: 719px) {
  .stack-container {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

@media (max-width: 1204px) {
  .stack-cover {
    height: 1500px;
  }

  .stack-container {
    padding: 100px 24px !important;
  }
  .stack-container .stack-logo-container {
    margin-top: 40px;
    min-height: 100vh;
    display: block;
  }

  .stack-container .stack-logo-container .my-logo {
    display: none;
  }
  .stack-container .stack-logo-container .my-mobile-logo {
    margin-left: 0;
    width: 207px !important;
    display: block;
    margin: 0 auto;
    transform: translateY(-25px);
  }
  .stack-logo-container .stark-flex {
    transform: rotate(90deg);
    margin-top: 0;
    justify-content: inherit;
  }
  .stack-logo-container .stark-flex .start-flex_item:nth-child(2) {
    align-items: flex-end;
  }

  .stack-logo-container .stark-flex .start-flex_item:nth-child(4) {
    margin-top: 0px;
  }
  .stack-logo-container .stark-flex .start-flex_item:nth-child(5) {
    margin-top: 0px;
  }

  .logo-container {
    width: 120px;
    height: 120px;
    transform: none;
  }
}

@media (max-width: 1270px) {
  .stack-container .stack-logo-container .my-logo {
    width: 300px !important;
    transform: unset !important;
  }
}
/* @media (max-width: 1439px) {
  .stack-container .stack-logo-container .my-logo {
    width: 300px;
    transform: unset !important;
  }
} */

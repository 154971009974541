.img-container {
  width: 372px;
  position: relative;
}

.img-container .profile-pic {
  width: 100%;
  margin-top: 120px;
  min-width: 400px;
}
.img-container .profile-pic.about-pic {
  margin-top: 81px;
  width: 529px;
}

.img-container .img-text-container {
  position: absolute;
  width: -moz-fit-content;
  width: fit-content;
  right: -140px;
  top: 301px;
  z-index: 2;
}

.img-container .img-text {
  background: var(--bg-text-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  width: fit-content;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.img-text-container .img-text:first-child::before {
  content: "";
  width: 38px;
  height: 2px;
  background: var(--bg-text-gradient);
  position: absolute;
  right: 0;
  top: 16px;
  margin-left: 16px;
  transform: translateX(16px);
}

/* =========== MEDIA QUERIES (SMALL DEVICES) =========== */
@media (max-width: 1204px) {
  .img-container {
    width: 100%;
  }

  .img-container .profile-pic {
    margin-top: 119px !important;
    min-width: unset;
  }

  .img-container .img-text-container {
    right: 16px;
    top: 150px;
  }

  .img-container .img-text {
    font-size: 20px;
    line-height: 26px;
  }
  .img-container .profile-pic.about-pic {
    margin-left: 0;
    margin-top: 37px !important;
    width: 342px;
  }
}

@media (max-width: 1439px) {
  .img-container .profile-pic {
    margin-top: 105px;
  }
}

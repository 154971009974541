.nav-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-wrapper .logo {
  padding-right: 56px;
}

.nav-wrapper .nav-items:first-child {
  display: flex;
}

.nav-items .nav-text-container {
  border-left: 1px solid #2f2e33;
  border-right: 1px solid #2f2e33;
}
.nav-text {
  border-left: 1px solid #2f2e33;
  max-width: 359px;
  border-right: 1px solid #2f2e33;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  padding: 26px 40px 29px 40px;
}

.nav-items:last-child {
  display: flex;
  align-items: center;
}

.line {
  border-bottom: 1px solid #2f2e33;
  margin-left: calc(-100vw / 2 + 1170px / 2);
  margin-right: calc(-100vw / 2 + 1170px / 2);
}

.nav-links {
  display: flex;
  /* margin-left: 40px; */
  gap: 40px;
  transition: ease-in 0.15s;
}

.ul-nav-item .link {
  color: var(--text-color);
  font-size: 12px;
}

.nav-items {
  cursor: pointer;
}

.nav-items .weather-icon {
  width: 53px;
}

.nav-items .weather-text {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-left: 8px;
}

.menu-toggle {
  display: none !important;
}

.the-nav-bar-menu {
  display: none !important;
}

/* =============== SMALL DEVICES =============== */

@media (max-width: 1204px) {
  .nav-container {
    position: fixed;
    min-width: 100%;
    z-index: 5;
    left: 0;
    top: 0;
    padding: 0 24px;
  }

  .the-nav-bar[data-is-reduced="true"] {
    background: var(--bg-color);
    background-image: url(../../assets/images/texture.png);
    background-repeat: repeat;
    background-size: contain;
    background-position: center;
    opacity: 0.9;
    left: 0;
    width: 100%;
  }

  .nav-wrapper {
    padding-top: 48px;
    padding-bottom: 33px;
  }

  .nav-wrapper .logo {
    padding-right: 0;
  }

  .nav-wrapper .logo .nav-logo-img {
    width: 32px;
  }
  .nav-items {
    height: 32px;
  }

  .nav-items .nav-text-container {
    display: none;
  }

  .nav-items .weather-icon {
    width: 40px;
  }

  .nav-items .weather-text {
    display: none;
  }

  .nav-wrapper .nav-items:first-child {
    flex: 1;
  }

  .line {
    margin-left: calc(-100vw / 2 + 342px / 2);
    margin-right: calc(-100vw / 2 + 342px / 2);
  }

  nav {
    align-items: flex-start !important;
    padding-top: 115px !important;
    justify-content: center !important;
  }

  ul li {
    margin-right: 0;
  }

  .menu-toggle {
    cursor: pointer;
    position: relative;
    left: 90%;
    top: -50%;
    transform: translateX(-50%);
    z-index: 10;
    display: block !important;
    margin-top: -5px;
  }

  .hamBox {
    position: relative;
    width: 40px;
    height: 16px;
    cursor: pointer;
    border-radius: 50%;
    transition: 0.3s ease;
    padding: 2.4em;
  }

  .lineTop,
  .lineMiddle,
  .lineBottom {
    position: absolute;
    display: block;
    width: 32px;
    height: 2px;
    border-radius: 100px;
    background: var(--text-color);
    left: 0;
    right: 0;
    transform: rotate(0);
    transition: all 0.4s;
  }

  .lineMiddle {
    width: 40px;
    height: 2px;
    transform: translateX(-3.5px);
  }

  .lineTop {
    top: 2em;
  }

  .lineBottom {
    bottom: 2em;
  }

  .lineTop.spin {
    top: 2.35em;
    transform: rotate(135deg);
    background: var(--text-color);
  }

  .lineMiddle.spin {
    background: transparent;
  }

  .lineBottom.spin {
    bottom: 2.35em;
    transform: rotate(225deg);
    background: var(--text-color);
  }

  .nav-links {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    gap: 55px;
    flex-direction: column;
    margin-left: 0;
    pointer-events: none;
    /* padding: 50%; */
    transition: 0.3s ease-out;
  }

  .nav-links.activenavLinks {
    opacity: 1;
    pointer-events: unset;
  }

  .nav-links li {
    position: relative;
    text-transform: uppercase;
  }

  .nav-links li :last-child {
    white-space: nowrap;
  }

  .nav-links .link {
    position: relative;
    top: 0;
    z-index: 7;
    font-size: 30px;
    transition: ease-in;
    transition-duration: 0.3s !important;
    transform: translate(0px, 100px);
    opacity: 0;
  }

  .ul-nav-item-wrapper::after {
    content: "";
    position: absolute;
    /* top: 120px; */
    left: -10%;
    width: 100vw;
    height: 100%;
    z-index: 10;
    margin: 0 auto;
    opacity: 0.86;
  }

  .the-nav-bar-menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    display: block !important;
  }

  .nav-links .the-nav-bar-menu-bg {
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
    width: 100%;
  }

  .the-nav-bar-menu-bg span:first-child {
    transition-delay: 0.15s;
    background: var(--bg-color);
  }
  .the-nav-bar-menu-bg span:nth-child(2) {
    transition-delay: 0.3s;
    background: var(--bg-color);
  }
  .the-nav-bar-menu-bg span:nth-child(3) {
    transition-delay: 0.45s;
    background: var(--bg-color);
  }

  .the-nav-bar-menu-bg.activeBg span {
    transform: translate(0, 0);
  }
  .the-nav-bar-menu-bg span {
    transform: translate(0, -100%);
    display: block;
    transition-duration: 0.3s;
    height: 100%;
  }

  .the-nav-bar-menu.activeMenu {
    opacity: 1;
    pointer-events: unset;
  }
  .the-nav-bar-menu {
    pointer-events: none;
  }

  .fadeUpLink {
    opacity: 1 !important;
    transform: translate(0px, 0px) !important;
  }
}

.socials-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  position: absolute;
  left: 0;
  bottom: 106px;
}

.socials-container a {
  position: relative;
  z-index: 2;
  background: #2b2b30;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.socials-container .icon-wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  position: relative;
}

.socials-container .icon-wrapper .tooltip {
  background: #2b2b30;
  font-size: 20px;
  padding: 10px 18px;
  border-radius: 25px;
  position: absolute;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.socials-container .icon-wrapper:hover .tooltip {
  left: 60px;
  opacity: 1;
  pointer-events: auto;
}

.socials-container .icon-wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #2b2b30;
  left: -5px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.socials-container .github:hover a,
.socials-container .github:hover .tooltip,
.socials-container .github:hover .tooltip::before {
  background: #333;
}
.socials-container .twitter:hover a,
.socials-container .twitter:hover .tooltip,
.socials-container .twitter:hover .tooltip::before {
  background: #000000;
}
.socials-container .instagram:hover a,
.socials-container .instagram:hover .tooltip,
.socials-container .instagram:hover .tooltip::before {
  background: #e1306c;
}
.socials-container .linedin:hover a,
.socials-container .linedin:hover .tooltip,
.socials-container .linedin:hover .tooltip::before {
  background: #0072b1;
}

.about-media{
  margin-top: 30px;
}

/* =========== MEDIA QUERIES (SMALL DEVICES) =========== */
@media (max-width: 1204px) {
  .socials-container {
    position: unset;
  }
  .phone-media {
    display: none;
  }
}

.footer-section {
  margin: 0;
}

.footer {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  max-width: var(--container-width-lg);
  margin: 0 auto;
  padding-top: 120px;
  background-image: url(../../assets/svg/map.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
}

.footer-container .footer-container_flex {
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  padding: 0 80px;
}

.footer-container_flex
  .footer-container_flex_item
  .footer-container_flex_item_text {
  font-size: 64px;
  line-height: 80px;
  max-width: 645px;
  font-weight: 700;
  color: var(--bg-text-gradient);
  position: relative;
}

.footer-container_flex .footer-container_flex_item .love-emoji {
  width: 69px;
  animation-name: moving-emoji;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

.mid-footer-section {
  margin-top: 48px;
}

.mid-footer-section_container {
  display: flex;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  justify-content: flex-end;
  padding: 0 80px;
}

.mid-footer-section_container .mid-footer-section_container_text {
  max-width: 571px;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  margin-right: 97px;
}

.mid-footer-section_container .arrow-right {
  margin-right: 97px;
}

.media-section-wrapper {
  margin-top: -250px;
  padding-left: 80px;
  padding-right: 80px;
}

.media-section-wrapper .media-section-wrapper_flex {
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.media-section-wrapper_flex .footer-socials {
  position: unset;
  transform: translateY(35px);
}

.media-section-wrapper_flex .copyright {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  align-self: flex-end;
  transform: translateY(30px);
}

.mid-footer-section_container .footer-text-pin {
  position: relative;
}

.jaykay {
  color: var(--bg-text-gradient);
}

/* =========== MEDIA QUERIES (SMALL DEVICES) =========== */
@media (max-width: 1204px) {
  .footer-section {
    overflow: hidden;
    min-height: 100vh;
  }
  .footer {
    max-width: var(--container-width-ms);
    padding: 80px 24px !important;
    background-size: 252%;
    display: block !important;
  }

  .footer-container {
    max-width: 342px;
    margin: 0 auto;
  }

  .footer-container .footer-container_flex {
    padding: 0;
  }

  .footer-container_flex .footer-container_flex_item:first-child {
    font-size: 46px;
    line-height: 150%;
    letter-spacing: 0.04em;
  }

  .footer-container_flex
    .footer-container_flex_item
    .footer-container_flex_item_text {
    font-size: 48px;
  }

  .footer-idea {
    width: 220px;
    display: flex;
  }

  .love-emoji {
    width: 44px !important;
  }

  .mid-footer-section {
    margin-top: 16px;
  }

  .mid-footer-section_container {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
  }
  .mid-footer-section_container .mid-footer-section_container_text {
    max-width: 342px;
    margin-right: 0;
    margin-bottom: -52px;
  }

  .mid-footer-section_container .arrow-right {
    margin-right: 0;
    margin-top: 0;
    align-self: flex-end;
    transform: rotate(90deg) translateX(105px);
    padding-top: 81px;
  }

  .mid-footer-section_container .footer-btn {
    margin-top: 0px;
    align-self: flex-end;
    transform: translateY(133px);
  }

  .media-section-wrapper {
    padding-bottom: 0 !important;
    background-size: cover;
    padding: 0;
    margin: 0;
  }

  .media-section-wrapper_flex .footer-socials {
    transform: translateY(-7px);
  }
  .media-section-wrapper .media-section-wrapper_flex {
    max-width: 342px;
    margin: 0 auto;
  }

  .work {
    padding-left: 4px;
  }
}

.span-div {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.gradient {
  background: #d3b6b8;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.SlidUp {
  display: inline-block;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  opacity: 0;
  transform: translateY(20px);
}

.WordSlidUp {
  display: inline-block;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  opacity: 0;
  transform: translateY(20px);
}

.page-can-play .SlidUp {
  animation-name: slidUp;
}
.page-can-play .WordSlidUp {
  animation-name: wordSlidUp;
}

.fadeLeft {
  animation-name: fadeLeft;
  animation-duration: 0.5s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  opacity: 0;
  transform: translateY(20px);
}
.fadeRight {
  animation-name: fadeRight;
  animation-duration: 0.5s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  opacity: 0;
  transform: translateY(20px);
}

@keyframes slidUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes wordSlidUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(150px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes fadeRight {
  0% {
    opacity: 0;
    transform: translateX(-150px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@media (max-width: 1200px) {
  .SlidUp {
    opacity: 1 !important;
    transform: translate(0, 0) !important;
    animation-name: none !important;
  }
}

@media (max-width: 1439px) {
  .SlidUp {
    display: inline !important;
  }
}

.logo-container {
  display: flex;
  flex-direction: column;
  background: #202025;
  box-shadow: 0px 8px 16px rgba(11, 11, 17, 0.2);
  border-radius: 16px;
  width: 168px;
  height: 168px;
  justify-content: center;
  align-items: center;
  transition: ease-in 0.2s;
}

.logo-container:hover {
  transform: scale(1.2);
}

.logo-container .logo-img {
  margin-bottom: 24px;
}

.logo-container .logo-img.img {
  width: 71px;
}
.logo-container .logo-name {
  background: var(--bg-text-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
}
.sub-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* =========== MEDIA QUERIES (SMALL DEVICES) =========== */
@media (max-width: 1204px) {
  .logo-container.logo-top {
    margin-left: -150px;
  }

  .sub-logo-container {
    transform: rotate(270deg);
  }

  .logo-container:hover {
    transform: none !important;
  }

  .logo-container .logo-img {
    width: 44px;
  }
}

@media (max-width: 1439px) {
  .logo-container {
    width: 150px;
    height: 150px;
  }
}

.experience-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.experience-header {
  margin-bottom: 48px;
  text-transform: none !important;
}

.exp-msg_container {
  display: flex;
  position: relative;
}

.exp-msg_header {
  margin-bottom: 48px !important;
}

.msg {
  position: absolute;
  right: 395px;
  bottom: 0;
}

.experience-container .experience-table {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  line-height: 32px;
  border-top: 2px solid #29292f;
  padding-top: 40px;
  padding-bottom: 56px;
}

.experience-table .arrow-left {
  transform: translate(-35px, -20px);
}

.experience-container .experience-table:last-child {
  padding-bottom: 0;
}

.experience-table .experience-date {
  flex: 0.5;
}

.experience-table:nth-child(3) .experience-date {
  flex: 0.52;
}
.experience-table:nth-child(4) .experience-date {
  flex: 0.63;
}
.experience-table:nth-child(5) .future-experience {
  transform: translateX(35px);
}

.experience-container .experience-table .future-experience::after {
  content: " ";
  width: 175px;
  height: 2px;
  display: block;
  background: var(--bg-text-gradient);
}
.experience-table .table-text {
  max-width: 409px;
}

.experience-table .future-experience {
  background: var(--bg-text-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 32px;
  font-weight: 700;
}

/* =========== MEDIA QUERIES (SMALL DEVICES) =========== */
@media (max-width: 1204px) {
  .experience-container {
    padding-bottom: 80px !important;
    padding-top: 80px !important;
  }

  .experience-container .experience-table {
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 46px;
  }

  .experience-table .experience-date {
    padding: 12px 0;
  }

  .experience-table .arrow-left {
    width: 81px;
    transform: rotate(80deg) translate(0);
    padding-top: 50px;
    padding-bottom: 12px;
  }
  .experience-container .experience-table .future-experience {
    padding-top: 12px;
  }

  .experience-table:nth-child(5) .future-experience {
    transform: translateX(0);
  }

  .exp-msg_header {
    margin-bottom: 0 !important;
  }

  .msg {
    display: none;
  }
}

@media (max-width: 1439px) {
  .experience-container .experience-table {
    display: flex !important;
  }
}

#project {
  height: 400vh;
}
.project-wrapper {
  min-height: 100vh;
  background-image: url(../../assets/images/texture.png);
  background-repeat: repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  position: sticky;
  top: 0;
}

.project-container {
  /* height: 100vh; */
  padding: 50px 135px 50px 135px !important;
  max-width: 1440px !important;
  margin: 0 auto !important;

  display: flex;
  align-items: center;
}

.project-container .project-flex {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.project-container-items {
  display: grid;
  flex-direction: column;
  justify-content: center;
  position: relative;
  grid-template-rows: 64px 125px 250px 356px;
}

.project-container-items .pin-img {
  width: 64px;
}

.project-flex .project-flex_items:first-child {
  display: flex;
  gap: 32px;
  flex-shrink: 0;
}

.project-flex .project-flex_items .project-number {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.project-flex .project-flex_items .project-num {
  background-image: linear-gradient(89.94deg, #d3b6b8 2.07%, #d3b6b8 99.94%);
  background-clip: text;
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: 3px;
  -webkit-background-clip: text;
  color: var(--bg-color);
  width: fit-content;
  font-weight: 700;
  font-size: 72px;
  line-height: 80px;
}

.project-flex .project-flex_items .project-head {
  font-weight: 700;
  font-size: 72px;
  line-height: 80px;
  letter-spacing: 0.01em;
  color: #d3b6b8;
}

.project-head-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-flex .project-flex_items .project-subtext {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  max-width: 552px;
}

.connect-lines--container {
  position: relative;
  transform: translateY(21px);
  transform: translateX(-5px);
}

.connect-lines--container .connect-line {
  height: 70px;
  width: 8px;
}

.connect-lines--container .indicator {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  overflow: hidden;
}

.project-container-items .image_items_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  transform: translateY(-38px);
}

.project-container-items .image_items_container .image_items_container_items {
  width: min-content;
}

.project-container-items
  .image_items_container
  .image_items_container_items:first-child {
  transform: translateY(125px);
}

.project-container-items
  .image_items_container
  .image_items_container_items:last-child {
  position: absolute;
  right: 0;
}

.btn-container-mobile {
  display: none;
}

.project-container-items
  .image_items_container
  .image_items_container_items
  .image_items_container_items_wrapper {
  position: relative;
  width: min-content;
}

.project-container-items
  .image_items_container
  .image_items_container_items:first-child
  .project-img {
  width: 658px;
  margin-top: 12px;
}

.project-container-items
  .image_items_container
  .image_items_container_items:last-child
  .project-img {
  width: 553px;
  display: block !important;
}

.project-container-items
  .image_items_container
  .image_items_container_items
  .sub-img {
  top: 26px;
  position: absolute;
}

.hide-on-desktop {
  display: none;
}

.project-container-items
  .image_items_container
  .image_items_container_items
  .image_items_container_items_wrapper
  > *:not(.project-img) {
  position: absolute !important;
}

.control-key-wrapper {
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  transform: translateX(-55px);
  margin-top: 16px;
  font-weight: 500;
  color: #ebebeb;
}

.control-key {
  margin: 0 4px;
  font-weight: 700;
  border-radius: 100px;
  background: #29292f;
  padding: 4px 6px;
  transform: translateY(-4px);
}

.mobile-project-head {
  display: none;
}

/* ========== Keyframes ========== */
@keyframes fadeup {
}

/* =========== MEDIA QUERIES (SMALL DEVICES) =========== */

@media (max-width: 1204px) {
  #project {
    height: unset !important;
    overflow-x: hidden;
  }

  .hide-on-desktop {
    display: block !important;
  }

  .project-container {
    height: unset !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    flex-direction: column;
    max-width: var(--container-width-ms) !important;
    margin: 0 auto;
  }

  .project-wrapper {
    height: unset !important;
    width: 100%;
    padding: 120px 24px 40px 24px !important;
  }

  .mobile-project-head {
    display: block;
    max-width: var(--container-width-ms) !important;
    margin: 0 auto;
  }

  .project-container .project-flex {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
    gap: 0;
  }

  .project-container-items {
    height: 880px !important;
    display: block;
  }

  .project-container-items .header {
    display: none;
  }

  .project-container-items .pin-img {
    display: none;
  }

  .project-flex .project-flex_items:first-child {
    margin-top: 40px;
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;
  }

  .project-flex .project-flex_items .project-number {
    align-items: unset;
  }

  .project-flex .project-flex_items .project-num {
    font-size: 56px;
    margin-bottom: 0px;
    line-height: 64px;
  }

  .project-container-items .image_items_container {
    flex-direction: column;
    transform: unset;
  }

  .connect-lines--container {
    transform: translateX(15px);
  }

  .project-flex .project-flex_items:nth-child(2) {
    margin-top: 0;
  }
  .project-flex .project-flex_items:nth-child(2) {
    position: inherit;
    left: 0;
  }
  .project-flex .project-flex_items .project-head {
    font-size: 40px;
    line-height: 51px;
  }
  .project-flex .project-flex_items .project-subtext {
    font-size: 16px;
    transform: unset;
  }

  .project-container-items
    .image_items_container
    .image_items_container_items:first-child {
    margin-top: 32px;
    align-items: flex-start;
    display: none !important;
  }

  .btn-container {
    display: none;
  }

  .btn-container-mobile {
    margin-top: 32px;
    display: block;
  }

  .project-container-items
    .image_items_container
    .image_items_container_items:last-child
    .project-img {
    width: 100%;
  }

  .project-container-items
    .image_items_container
    .image_items_container_items
    .image_items_container_items_wrapper {
    transform: unset;
    width: 350px !important;
  }

  .project-container-items
    .image_items_container
    .image_items_container_items
    .sub-img {
    top: 16px;
  }

  .project-container-items
    .image_items_container
    .image_items_container_items:first-child
    .project-img {
    display: none;
  }

  .project-container-items
    .image_items_container
    .image_items_container_items:last-child {
    margin-top: 80px;
    position: unset;
  }

  .control-key-wrapper {
    display: none;
  }
}

@media (max-width: 1439px) {
  .project-flex .project-flex_items .project-head {
    font-size: 55px;
  }

  .control-key-wrapper {
    transform: translateX(-220px);
  }
}

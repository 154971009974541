.personal-interest_wrapper {
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

.interest-container {
  min-height: unset !important;
}

.experience-header {
  margin-bottom: 96px;
}

.hobbies {
  display: flex;
  gap: 200px;
  margin-bottom: 74px;
  max-width: var(--container-width-lg);
  margin: 0 auto;
  padding: 0 80px;
}

.hobbies .hobbie {
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
  letter-spacing: 0.02em;
  font-family: "ClashDisplay-Bold";
  color: rgba(255, 255, 255, 0.24);
  cursor: pointer;
}

.hobbies .active-tab {
  color: var(--text-color);
}

.hobbie-flex {
  display: grid;
  grid-template-columns: 385px 1fr;
  gap: 104px;
  margin-top: 74px;
}

.hobbie-flex .hobbie-flex_left {
  padding-top: 22px;
}

.content-tab {
  display: flex;
  max-width: var(--container-width-lg);
  margin: 0 auto;
  padding: 0 0 0 80px;
}

.content-tab .content {
  display: none !important;
}

.content-tab .active-content {
  display: grid !important;
}

.mobile-content-tab {
  display: none;
}

.hobbie-flex .hobbie-flex_left .hobbie-flex_left_text {
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 64px;
  max-width: 385px;
}

.hobbie-flex .hobbie-flex_right {
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  overscroll-behavior-inline: contain;
  gap: 32px;
}

.hobbie-flex .hobbie-flex_right .hobbie-flex_right_card {
  width: 350px;
  border-radius: 56px;
}

.hobbie-flex
  .hobbie-flex_right
  .hobbie-flex_right_card
  .hobbie-flex_right_card_img {
  width: 100%;
}

.hobbie-flex .hobbie-flex_right .hobbie-flex_right_card:last-child {
  margin-right: 0;
}

/* =============== SMALL DEVICES =============== */

@media (max-width: 1204px) {
  .personal-interest_wrapper {
    min-height: unset !important;
    display: block;
    padding-top: 80px;
    padding-bottom: 120px;
  }

  .experience-header {
    margin-bottom: 56px !important;
  }

  .exp-msg_header{
    margin-bottom: 0;
  
  }
  .content-tab {
    display: none;
  }
  .hobbies {
    flex-direction: column;
    gap: 32px;
    margin-bottom: 0;
    max-width: var(--container-width-ms);
    padding: 0 24px;
  }

  .mobile-content-tab {
    display: block;
  }

  .mobile-content-tab .content {
    display: none !important;
  }

  .hobbies .hobbie {
    font-size: 32px;
    line-height: 32px;
  }

  .hobbie-flex {
    display: block;
    margin-left: 0px;
    margin-top: 0px;
  }

  .hobbie-flex .hobbie-flex_right {
    gap: 24px;
  }

  .hobbie-flex .hobbie-flex_left {
    padding-top: 24px;
    margin-bottom: 40px;
  }

  .hobbie-flex .hobbie-flex_left .hobbie-flex_left_text {
    margin-bottom: 32px;
  }

  .hobbie-flex .hobbie-flex_right .hobbie-flex_right_card {
    width: 282px;
    height: 320px;
  }
}

.hero-container {
  position: relative;
  padding-bottom: 57px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.hero-wrapper .hero-sub-content_container {
  display: flex;
  justify-content: space-between;
}

.hero-head-span {
  display: flex;
  position: absolute;
  top: 240px;
}

.hero-head-span span {
  width: 359px !important;
  height: 96px !important;
  background: var(--bg-color);
  display: inline-block;
  background-image: url(../../assets/images/texture.png);
  background-repeat: repeat;
  background-size: contain;
  background-position: center;
}

.hero-head-span span:last-child {
  transform: translateX(-3px);
}

.hero-head-span span.activeSpan {
  opacity: 0;
  transition-delay: 0.5s;
}

.hero-sub-content_container .arrow-down {
  transform: translateX(35px);
  margin-top: 135px;
  position: relative;
  z-index: 2;
  height: fit-content;
}

.hero-sub-content_container .frontend-dev-wrapper {
  position: relative;
  z-index: 2;
  width: 558px;
}
.hero-sub-content_container .frontend-dev-wrapper .f-d {
  text-align: right;
}

.f-d-wrapper {
  transform: translateY(96px);
  transition: 0.5s ease-out;
  position: relative;
  right: 69px;
  z-index: 1;
}

.f-d-wrapper.active-f-d-wrapper {
  transform: translateY(0);
  background: transparent;
}

.hero-sub-content_container .f-d {
  margin-top: 285px;
  z-index: 1;
  right: 0;
}

.hero-sub-content_container .f-d .f-d_items {
  font-family: "Parabole" !important;
  color: var(--bg-text-gradient);
  font-weight: 700;
  font-size: 88px;
  line-height: 120px;
  text-transform: uppercase;
  position: relative;
  top: 0;
}

.hero-sub-content_container .f-d .f-d_items:first-child {
  text-align: right;
}

.hero-sub-content_container .f-d:last-child {
  background-image: var(--bg-text-gradient);
  background-clip: text;
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-background-clip: text;
}

.f-d-span {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.f-d-span span:last-child {
  transform: translateY(4px);
}

.hero-wrapper .hero-wrapper_items:first-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-wrapper_items .blur-skeleton-container .blur-container {
  width: 442px;
  margin-left: 76px;
  position: absolute;
  top: 0;
  right: 0;
}

.hero-wrapper_items .blur {
  width: 100%;
}

.hero-wrapper_items .blur-skeleton-container .skeleton-container {
  width: 692px;
  position: absolute;
  top: 65px;
  transform: translateX(260px);
  right: 0;
}

.hero-wrapper_items .skeleton {
  width: 100%;
}

.hero-wrapper .hero-wrapper_items_text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 24px;
  transform: translateY(55px);
}

.hero-head-wrapper {
  position: relative;
  top: 55px;
}

.hero-head-wrapper .hero-head {
  display: flex;
  white-space: nowrap;
  gap: 48px;
}

.hero-wrapper .hero-head span {
  background: linear-gradient(89.94deg, #d3b6b8 2.07%, #d3b6b8 99.94%);
  background-clip: text;
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: 3px;
  -webkit-background-clip: text;
  color: var(--bg-color);
  font-weight: 700;
  font-size: 80px;
  line-height: 96px;
  width: fit-content;
}

.hero-wrapper .hero-head:last-child {
  position: absolute;
  top: 0;
}

.hero-wrapper .hero-head:last-child span {
  -webkit-text-stroke-color: unset;
  -webkit-text-stroke-width: unset;
  color: transparent;
  background-image: url(../../assets/images/texture.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.hero-tiny-text {
  bottom: 0px;
  right: 0;
  z-index: 1;
  text-align: right;
  font-size: 20px;
  white-space: nowrap;
  margin-top: -16px;
}

.ui {
  line-height: 24px;
  margin-right: 0 !important;
}

@media (max-width: 1204px) {
  .hero-container {
    display: block;
    height: 100%;
  }

  .hero-wrapper {
    justify-content: flex-start;
  }

  .hero-head-span {
    max-width: 342px;
    margin: 0 auto;
    flex-direction: column;
    gap: 20px;
    top: 203px;
  }

  .hero-head-span span {
    width: 215px !important;
    height: 49px !important;
  }

  .hero-head-span span:last-child {
    transform: translateX(0);
    transform: translateY(20px);
  }

  .hero-wrapper .hero-head span {
    font-size: 48px;
    line-height: 80px;
  }

  .hero-wrapper .hero-head {
    flex-direction: column;
    gap: 0;
  }

  span:first-child {
    margin-right: 0;
  }

  .hero-sub-content_container .arrow-down {
    margin: 0;
    display: flex;
    justify-content: space-around;
    margin-top: 32px !important;
    margin-bottom: 32px;
    transform: translate(0) rotate(90deg);
  }

  .hero-sub-content_container .arrow-down img {
    width: 106px;
  }

  .hero-sub-content_container .frontend-dev-wrapper {
    transform: unset;
    text-align: right;
    width: 100%;
    right: 0 !important;
  }

  .hero-sub-content_container .f-d {
    margin-top: 0;
  }
  .hero-sub-content_container .f-d .f-d_items {
    font-size: 48px !important;
    line-height: 64px;
  }
  .hero-wrapper .hero-sub-content_container {
    flex-direction: column;
  }

  .hero-wrapper_items .blur-skeleton-container {
    display: none;
  }

  .hero-wrapper .hero-wrapper_items_text {
    padding-top: 120px;
  }

  .hero-tiny-text {
    font-size: 16px;
    bottom: -180px;
    margin-top: 0;
  }

  .ui {
    display: block;
  }
}

@media (max-width: 1439px) {
  .hero-head-wrapper {
    top: 80px;
  }

  .hero-wrapper .hero-head span {
    font-size: 65px;
  }

  .hero-sub-content_container .frontend-dev-wrapper {
    right: 50px;
  }

  .hero-sub-content_container .f-d .f-d_items {
    font-size: 80px;
  }

  .hero-wrapper .hero-wrapper_items_text {
    transform: translateY(99px);
  }

  .hero-sub-content_container .arrow-down {
    margin-top: 173px;
  }
}

.btn-wrapper {
  position: relative;
  width: fit-content;
  transition: ease-in 0.2s;
  z-index: 2;
}

.btn-wrapper:hover {
  transform: scale(1.1);
}

.btn-wrapper button .btn-link {
  color: var(--text-color);
}

button {
  background: #1f1f25;
  font-weight: 600;
  font-size: 14px;
  padding: 15px 22px;
  border: 1px solid #292929;
  border-radius: 8px;
  font-family: "Mulish";
  line-height: 16px;
  cursor: pointer;
}

.btn-vector {
  position: absolute;
  top: 0;
  bottom: 0;
  transform: translate(-18px, 0);
}

.btn-vector:last-child {
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(31px) rotate(-180deg);
}

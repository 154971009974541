.section {
  overflow: hidden;
}

@media (max-width: 1204px) {
  .header-container {
    padding: 0 !important;
  }

  .header-hero-wrapper {
    padding: 0 24px !important;
  }
}
